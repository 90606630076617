import React from "react";
import Navigation from "../../Layout/Navigation/Navigation";
import Styles from "./ContactUs.module.css";

const ContactUs = () => {
  return (
    <div className={Styles.App}>
      <Navigation />
      <div className={Styles.Hero}>
        <h1>Get in touch</h1>
        <div className={Styles.ContactForm}>
          <div className={Styles.ContactCard}>
            <h5>Contact Us</h5>
            <p>1 626 689 2713</p>
            <a href="mailto:info@data-bits.com">info@data-bits.com</a>
          </div>
          <div className={Styles.ContactCard}>
            <h5>Address</h5>
            <p>530 S. Lake Ave. Pasadena CA 91101</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
