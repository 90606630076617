import React from "react";
import Styles from "./Footer.module.css";
const Footer = () => {
  return (
    <div className={Styles.Footer}>
      <p>1 626 689 2713</p>
      <a href="mailto:info@data-bits.com">info@data-bits.com</a>
      <p>Copyright © 2020 Data-Bits LLC, All rights reserved.</p>
    </div>
  );
};

export default Footer;
