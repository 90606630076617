import React from "react";
import Navigation from "../../Layout/Navigation/Navigation";
import Styles from "./About.module.css";
import imgenrique from "../../images/team/enrique.png";
import imgandrew from "../../images/team/andrew.png";
import imgstacy from "../../images/team/stacy.png";
import imggary from "../../images/team/gary.jpeg";
import imgkilari from "../../images/team/kilari.png";
import imgyunyun from "../../images/team/yun-yun.png";
import Footer from "../../Layout/Footer/Footer";
const About = () => {
  const team = [
    {
      img: imgenrique,
      name: "Enrique",
      role: "Technical Director",
    },
    {
      img: imgandrew,
      name: "Andrew",
      role: "Managing director",
    },
    {
      img: imgstacy,
      name: "Stacy",
      role: "Client Relations",
    },
    {
      img: imggary,
      name: "Gary",
      role: "Data Analytics/Science",
    },
    {
      img: imgkilari,
      name: "Kilari",
      role: "Senior SRE",
    },
    {
      img: imgyunyun,
      name: "Yun-Yun",
      role: "Linguistics and Technology",
    },
  ];

  return (
    <div className={Styles.App}>
      <Navigation />
      <div className={Styles.Hero}>
        <h1>Our Company</h1>
        <h5>Innovators. Dreamers. Data Mavens</h5>
        <p>
          Data-Bits is a{" "}
          <strong>
            leading provider of cutting-edge technologies and services
          </strong>
          , offering scalable data discovery and processing solutions for
          organizations of all sizes. Founded by technology experts that
          pioneered the <strong>media analysis space for Fortune 50</strong> 0
          companies and crafted large scale data processing platforms for the
          <strong> Hikari Tsuhin</strong>, <strong>USDA</strong>, and{" "}
          <strong>Atlantic Media</strong>, today we offer smart, innovative
          services to clients worldwide.
        </p>
        <p>
          {" "}
          We build our solutions by closely listening to our clients and
          understanding your expectations. We know how to analyze this
          information and customize our offering to changing situational needs.
          Why not join our fast growing customer base? Get in touch today to
          learn more about the Data-Bits story.
        </p>
      </div>
      <div className={Styles.TeamGrid}>
        <div className={Styles.GridTitle}>
          <p>WHO WE ARE</p>
          <h5>Meet the team</h5>
        </div>
        <div className={Styles.GridContent}>
          {team.map((item, i) => {
            return (
              <div key={i} className={Styles.TeamCard}>
                <img src={item.img} alt="team-faces" width={350} />
                <h3>{item.name}</h3>
                <p>{item.role}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
