import React from "react";
import Styles from "./Navigation.module.css";
import logo from "../../images/data-bits-logo.webp";
import { useLocation, useNavigate } from "react-router-dom";
const Navigation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <nav className={Styles.NavigationWrapper}>
      <div className={Styles.NavigationContentWrapper}>
        <img src={logo} alt="logo" className={Styles.Logo} />

        <ul className={Styles.List}>
          <li
            style={pathname === "/" ? { opacity: "0.7" } : null}
            onClick={() => navigate("/")}
          >
            Home
          </li>
          <li
            style={pathname === "/about" ? { opacity: "0.7" } : null}
            onClick={() => navigate("/about")}
          >
            About
          </li>
          <li
            style={pathname === "/contact" ? { opacity: "0.7" } : null}
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
