import React from "react";
import Navigation from "../../Layout/Navigation/Navigation";
import Styles from "./Home.module.css";
import heroimg from "../../images/hero-image.png";
import quotes from "../../images/quotes.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../Layout/Footer/Footer";
import infocastlogo from "../../images/partners/infocast-logo.jpeg";
import microsoftlogo from "../../images/partners/microsoft-logo.png";
import nslogo from "../../images/partners/network-solutions-logo.png";
import skyrocklogo from "../../images/partners/skyrock-logo.png";
import wecampaignlogo from "../../images/partners/wecampaign-logo.png";
import zelogo from "../../images/partners/ze-logo.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className={Styles.App}>
      <Navigation />

      <div className={Styles.Hero}>
        <div className={Styles.LeftWrapper}>
          <h1>
            We Solve Your <br /> Big Data Problems
          </h1>
          <ul>
            <li>
              Customized solutions using AI, machine learning, advanced data
              mining
            </li>
            <li>
              Software development and architecture building software to your
              specifications
            </li>
            <li>Proprietary analytic services</li>
          </ul>
        </div>

        <img src={heroimg} alt="hero" width={380} />
      </div>
      <div className={Styles.StorySection}>
        <div className={Styles.StoryContent}>
          <h3>Our Story</h3>
          <p>
            Data-Bits is a leading developer of artificial intelligence
            technologies and data processing services, offering scalable
            solutions for organizations of all sizes. The company employs a
            group of technologists that helped invent the media intelligence
            space and works closely with data innovators across the globe to
            offer complete, unique solutions to clients worldwide.
          </p>
        </div>
      </div>
      <div className={Styles.Testimonials}>
        <h4>What people say</h4>
        <div className={Styles.TestimonialsWrapper}>
          <div className={Styles.TestimonialsCard}>
            <img src={quotes} alt="quotes" width={60} />
            <p>
              "One of the clearest and most comprehensive value propositions
              I've seen in the sector."
            </p>
            <p>Venture Capital Investor</p>
          </div>
          <div className={Styles.TestimonialsCard}>
            <img src={quotes} alt="quotes" width={60} />
            <p>
              "Enrique and his associates provide a refreshing, out of the box
              blend of creativity and technical know-how to solve some really
              tough challenges with ease."
            </p>
            <p>Senior Executive, Hikari-Tsushin Co</p>
          </div>
        </div>
      </div>
      <div className={Styles.CtaWrapper}>
        <div className={Styles.CtaContent}>
          <h5>Let's Work Together!</h5>
          <p>Contact us via our form.</p>
          <button onClick={() => navigate("/contact")}>Contact Us</button>
        </div>
      </div>
      <div className={Styles.PartnersWrapper}>
        <h5>Partners</h5>
        <div className={Styles.GridPartners}>
          {[
            zelogo,
            wecampaignlogo,
            skyrocklogo,
            nslogo,
            microsoftlogo,
            infocastlogo,
          ].map((item, i) => {
            return <img src={item} alt="partners" key={i} width={200} />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
